import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { SamplePrevArrow, SampleNextArrow } from "./Arrows"
import CustomSlide from "./CustomSlide"

import reviews from "../../../../information/MainPage/dataForSlider.json"

import "./Reviews.scss"

const settings = {
  dots: false,
  speed: 500,
  infinite: true,
  draggable: false,
  arrows: true,
  slidesToShow: 3,
  centerMode: true,
  slidesToScroll: 1,
  cssEase: "ease-out",
  useTransform: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false,
        dots: true,
        className: "center",
        speed: 400,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        className: "center",
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        className: "center",
      },
    },
    {
      breakpoint: 1250,
      settings: {
        arrows: false,
      },
    },
  ],
}

function Reviews({ isTestimonials=false }) {
  const [renderSlider, setRenderSlider] = useState(false)

  useEffect(() => {
    setRenderSlider(true)
  }, [])

  return (
    <div className="reviews-container">
      <div className="reviews-wrapper">
        <div className="reviews-title">Reviews</div>
        <div className="reviews-carousel-wrapper">
          {renderSlider && (
            <Slider {...settings}>
              {reviews.map((review, i) => {
                return <CustomSlide review={review} key={i} />
              })}
            </Slider>
          )}
        </div>
        {isTestimonials && (<div className="reviews-testimonials">READ <a href="https://clutch.co/profile/ossystem#reviews" target="_blank">TESTIMONIALS</a> FROM OUR CLIENTS ABOUT OUR WORK</div>)}
      </div>
    </div>
  )
}

export default Reviews
